import Rest from '../Rest'

export default class CertifiedUserEmitService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/certified_emit'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  dispatchCertifieds(certifiedUserEmitId) {
    return this.post(`/${certifiedUserEmitId}/emit`)
  }

  dispatchCertifiedUser(certifiedId, certifiedUserEmitId) {
    const destinations = [certifiedUserEmitId]
    return this.post(`/${certifiedId}/emit/${certifiedUserEmitId}/emit`, {
      destinations: destinations
    })
  }

  sendEmailCertifiedUser(certifiedId, certifiedUserEmitId) {
    const destinations = [certifiedUserEmitId]
    return this.post(`/${certifiedId}/emit/${certifiedUserEmitId}/sendmail`, {
      destinations: destinations
    })
  }

  redispatchCertifiedUser(certifiedId, certifiedUserEmitId) {
    const destinations = [certifiedUserEmitId]
    return this.post(`/${certifiedId}/emit/${certifiedUserEmitId}/reemit`, {
      destinations: destinations
    })
  }

}
