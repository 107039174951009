var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex flex-col gap-4" },
      [
        _c("div", { staticClass: "pb-8" }, [
          _c("div", { staticClass: "flex flex-col gap-2" }, [
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    id: "certified_name",
                    label: _vm.$t("certified.name"),
                    disabled: "",
                  },
                  model: {
                    value: _vm.model.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "name", $$v)
                    },
                    expression: "model.name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-2",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("vs-input", {
                      attrs: {
                        id: "duration",
                        type: "number",
                        label: _vm.$t("certified.duration_in_day"),
                        disabled: "",
                      },
                      model: {
                        value: _vm.model.duration,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "duration", $$v)
                        },
                        expression: "model.duration",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "flex col-span-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          id: "certificate_city",
                          label: _vm.$t("certified.certificate_city"),
                          disabled: "",
                        },
                        model: {
                          value: _vm.model.certificate_city,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "certificate_city", $$v)
                          },
                          expression: "model.certificate_city",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "flex items-center ml-5" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label vs-input--label",
                          attrs: { for: "sendEmail" },
                        },
                        [_vm._v(_vm._s(_vm.$t("certified.send_email")))]
                      ),
                      _c("vs-switch", {
                        attrs: { id: "sendEmail", disabled: "" },
                        model: {
                          value: _vm.model.send_email,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "send_email", $$v)
                          },
                          expression: "model.send_email",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    id: "institution",
                    label: _vm.$t("fields.institution"),
                    disabled: "",
                  },
                  model: {
                    value: _vm.model.institution.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.model.institution, "name", $$v)
                    },
                    expression: "model.institution.name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    id: "questionnaire",
                    label: _vm.$t("Questionnaire"),
                    disabled: "",
                  },
                  model: {
                    value: _vm.model.certified_commit.questionnaire_name,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.model.certified_commit,
                        "questionnaire_name",
                        $$v
                      )
                    },
                    expression: "model.certified_commit.questionnaire_name",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "w-full mt-3" }, [
              _c("div", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.model.description,
                    expression: "model.description",
                  },
                ],
                staticClass:
                  "simple-content simple-editor simple-editor-disabled",
              }),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "mt-2 w-full" },
          [
            _c("media-list", {
              attrs: {
                medias: _vm.files,
                "show-delete": false,
                "action-on-click": false,
              },
            }),
          ],
          1
        ),
        _c("certified-legend"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }