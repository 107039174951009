<template>
  <div>
      <div class="flex flex-col gap-4">
        <div class="pb-8">
          <div class="flex flex-col gap-2">
            <div class="w-full">
              <vs-input
                id="certified_name"
                :label="$t('certified.name')"
                v-model="model.name"
                disabled
                class="w-full"/>
            </div>
            <div class="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-2">
              <div class="flex">
                <vs-input
                  id="duration"
                  type="number"
                  :label="$t('certified.duration_in_day')"
                  v-model="model.duration"
                  disabled/>
              </div>
              <div class="flex col-span-2">
                <div class="form-group w-full">
                  <vs-input
                    id="certificate_city"
                    :label="$t('certified.certificate_city')"
                    v-model="model.certificate_city"
                    class="w-full"
                    disabled/>
                </div>
              </div>
              <div class="flex items-center ml-5">
                <div class="form-group">
                  <label for="sendEmail" class="control-label vs-input--label">{{ $t('certified.send_email') }}</label>
                  <vs-switch id="sendEmail"
                    v-model="model.send_email"
                    disabled/>
                </div>
              </div>
            </div>
            <div class="w-full">
              <vs-input
                id="institution"
                :label="$t('fields.institution')"
                v-model="model.institution.name"
                disabled
                class="w-full"/>
            </div>

            <div class="w-full">
              <vs-input
                id="questionnaire"
                :label="$t('Questionnaire')"
                v-model="model.certified_commit.questionnaire_name"
                disabled
                class="w-full"/>
            </div>
            <div class="w-full mt-3">
              <div class="simple-content simple-editor simple-editor-disabled" v-html-safe="model.description"/>
            </div>
          </div>
        </div>
        <div class="mt-2 w-full">
          <media-list :medias="files"
                      :show-delete="false"
                      :action-on-click="false" />
        </div>
        <certified-legend></certified-legend>
      </div>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'

import CertifiedService from '@/services/api/CertifiedService'
import MediaList from '@/components/media/MediaList.vue'

import CertifiedLegend from './CertifiedLegend.vue'

export default {
  directives: {

  },
  components: {
    MediaList,
    CertifiedLegend
  },
  props: {
    id: {
      default: null,
      type: Number
    },
    model: {
      type: Object
    }
  },

  data: () => ({
    service: null,
    model: {
      id: null,
      name : '',
      description: ''
    },
    files: [],
  }),
  computed: {

  },
  beforeMount() {
    this.service = CertifiedService.build(this.$vs)
  },
  mounted() {
    if(this.id){
      this.loadData(this.id)
    }
  },
  methods: {
    loadData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        response => {
          this.model = response
          this.files = response.files
        },
        error => {

        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/simple-content.scss';
.vs-list--header{
  display: inline !important;
}

</style>
